
import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
import BackButton from '@/views/module/common/BackButton.vue';


import app from '@/app/App';
import User from '@/app/com/main/module/business/user/bean/User';
import DataBackAction from '@/app/base/net/DataBackAction';
import GroupRelationController from '@/app/com/main/module/business/group/controller/GroupRelationController';
import GroupMemberService from '@/app/com/main/module/business/group/service/GroupMemberService';
import CommonIcon from '@/platform/common/web/CommonIcon';

import groupInfoViewModel from '@/platform/vue/view/model/GroupInfoViewModel';


import Prompt from '@/platform/wap/common/Prompt';
import GroupBox from '@/app/com/main/module/business/group/box/GroupBox';
import GroupRelationBox from '@/app/com/main/module/business/group/box/GroupRelationBox';
import Group from '@/app/com/main/module/business/group/bean/Group';
import GroupRelation from '@/app/com/main/module/business/group/bean/GroupRelation';
import GroupChatViewController from '@/app/com/main/module/business/chat/controller/GroupChatViewController';
import RouterUtil from '@/common/vue/RouterUtil';

@Component({
    components: {
        BackButton,
    },
})
export default class GroupInfoCard extends Vue {
    private infoBg: string = 'assets/general/wap/images/info/bg2.jpg';
    private model = groupInfoViewModel;
    private noLogo = CommonIcon.noLogo;
    private openDialog: boolean = false;
    private remark: string = '';

    @Prop({
        type: String,
        required: false,
        default: () => (''),
    })
    private groupId!: string;

    public mounted() {
        this.setGroupId(this.groupId);
    }

    public setGroupId(groupId: string) {
        // this.groupId = groupId;
        const groupBox: GroupBox = app.appContext.getMaterial(GroupBox);
        const box: GroupRelationBox = app.appContext.getMaterial(GroupRelationBox);
        const group: Group = groupBox.getGroup(groupId);
        let relation: GroupRelation = new GroupRelation();
        const list = box.getGroupInGroupRelationListByGroupId(groupId);
        if (list && list.length > 0) {
            relation = list[0];
        }
        this.setGroup(group, relation);
    }

    public setGroup(group: Group, relation: GroupRelation) {
        this.model.setGroup(group);
        this.model.setRelation(relation);
    }


    private getNickname(user: User): string {
        const service: GroupMemberService = app.appContext.getMaterial(GroupMemberService);
        const groupId = this.groupId;
        let nickname = '';
        if (user) {
            nickname = service.getUserShowName(groupId, user);
        }
        return nickname;
    }

    private openSend() {
        const groupId = this.groupId;
        this.onOpenSend(groupId);
    }

    // @Emit('on-to-send')
    private onOpenSend(key: string) {

        // this.$router.push({path: '/chat.user'});

        const groupChatViewController: GroupChatViewController = app.appContext.getMaterial(GroupChatViewController);
        groupChatViewController.showGroupChatById(key);
    }

    private openRemark(): void {
        const own = this;
        const groupId = this.groupId;
        const oldRemark = (this.model.relation) ? this.model.relation.remark : '';
        this.remark = oldRemark;
        this.openDialog = true;
    }

    private updateRemark(): void {
        const own = this;
        const groupId = this.groupId;
        const oldRemark = (this.model.relation) ? this.model.relation.remark : '';
        let text = this.remark;

        const back: DataBackAction = {
            back(data: any): void {
                if (data) {
                    const info = data.info;
                    if (info) {
                        if (info.success) {
                            own.model.relation.remark = text;
                            own.openDialog = false;
                        }
                    }
                }
            },
            lost(data: any): void {
                Prompt.notice('请求失败！');
            },
            timeOut(data: any): void {
                Prompt.notice('请求超时！');
            },
        } as DataBackAction;
        const ccc: GroupRelationController = app.appContext.getMaterial(GroupRelationController);
        if (!text) {
            text = '';
        }
        ccc.updateRemark(groupId, text, back);
    }

    private openMemberList() {
        const groupId = this.groupId;
        const path = '/group.info.member.list/' + groupId;
        RouterUtil.toByPath(path);
    }
}
